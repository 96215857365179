<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="secondary">mdi-account</v-icon> Users
                 <v-spacer/>
                   <span style="width: 190px; margin-right: 5px;">
                        <v-select
                            v-model="utype"
                            single-line
                            dense
                            :items="['All', 'General Coordinator', 'Coordinator', 'Agent', 'Player', 'Administrator', 'Monitoring' ]"
                            outlined
                            hide-details
                        />
                 </span>
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="user={}, state='new', form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add User</v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="FILTERED_ITEMS"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon  small left color="warning" @click="user=item, state='update', form=true" >mdi-pencil</v-icon>
                              <v-icon  x-small left color="warning" @click="user=item, trans_dailog=true" >mdi-format-list-text</v-icon>
                            {{ item.id }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.fullname }}</td>
                            <td>{{ item.role }}</td>
                            <td><v-icon left small :color="statusIndicator(item.last_login)">mdi-checkbox-blank-circle</v-icon> {{ $moment(item.last_login).fromNow()}}</td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
          <v-col cols="12">
             <v-card>
                <v-toolbar>
                 <v-icon left color="secondary">mdi-map-marker-radius</v-icon> Clusters
                 <v-spacer/>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="area={}, state='new', areaform=true"><v-icon small left color="success">mdi-plus</v-icon>  New Cluster</v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="ereasheaders"
                    :items="areas"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                               <td>
                                 <v-icon  x-small left color="warning" @click="area=item, state='update', areaform=true" >mdi-pencil</v-icon>
                                 {{ item.name }}</td>
                                <td>{{  item.areas != null?item.areas.length:0 }}</td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
          <!-- <v-col cols="4">
               <div class="pa-3 small-font" >
                <p>USER ROLES:</p>
                <p>1. Super Admin</p>
                <p>2. Administrator</p>
                <p>3. A</p>
                <p>4. Cashier</p>
                <p>5. Player</p>
             </div>
          </v-col> -->
    </v-row>
    </v-container>
    <va-user-form :show="form" :account="user" :users="users" :areas="areas" :state="state" @DialogEvent="formEvent" />
    <va-area-form :show="areaform" :area="area" :state="state" @DialogEvent="afEvent" />
    <va-transaction-list :show="trans_dailog" :user="user" @DialogEvent="transEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        trans_dailog: false,
        areaform: false,
        utype: "All",
        state: 'new',
        user: {},
        search:'',
        areas:[],
        area:{},
        users:[],
        headers: [
          { text: 'Account No.', value:"id"},
            { text: 'User', value:"username"},
            { text: 'Fullname', value:"fullname"},
            // { text: 'Unit Serial.', value:"machine_id"},
            { text: 'Type', value:"role"},
            { text: 'Logged-in' }
        ],
        ereasheaders: [
            { text: 'Name'},
            { text: 'Covered'}
        ],
         role_headers: [
            { text: '#'},
            { text: 'Role'}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getUsers()
          this.getAreas()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      FILTERED_ITEMS() {
        if(this.utype == "All") {
          return this.users
        }
         let list =  this.users.reduce((res, item) =>{
                    if(item.role ==  this.utype) {
                          res.push(item)
                    }
                    return res
                }, [])
              
        return list
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      transEvent(){
        this.trans_dailog = false
      },
      statusIndicator(_time) {
          //var current = this.$moment(_time)
          var a = this.$moment()
            var b = this.$moment(_time)
           var diff = a.diff(b, 'hour')
           if(diff <=  3) {
              return 'success'
           } else if(diff > 3 && diff < 5) {
               return '#A5D6A7'
           } else {
             return '#989898'
           }
      },
      CountBarangay(area){
          var _count = area.reduce((res,item)=>{
                res += item.barangays.length
                return res
          },0) 
          return _count
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.user = data.user
              this.Save()
          }
      },

      getAreas(){
           this.$http.get("area/list",).then(response => {
            response.data.areas != null?this.areas = response.data.areas:this.areas =[]
           // console.log(this.areas)
          }).catch(e => {
            console.log(e.data)
          })
      },
      
      getUsers: function() { 
        this.$http.post("user/list", JSON.stringify({})).then(response => {
            response.data.data != null?this.users = response.data.data:this.users =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      Save: function() {
        let method = 'user/register'
        this.state != 'new'?method='user/update':method = 'user/register'
        this.$http.post(method, JSON.stringify(this.user)).then(response => {
            response.data.status? this.setAlertColor('success'): this.setAlertColor('error')
            this.setAlertText(response.data.message)
            this.setAlert(true)
            this.getUsers()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>